"use client";

import Image from "next/image";
import Link from "next/link";
import { cn } from "@/lib/utils";
import dynamic from "next/dynamic";
import { appendUtmParams } from "@/lib/utm";

const VideoPlayer = dynamic(() => import("@/components/shared/VideoPlayer"), { ssr: false });

const videoData = [
  {
    clip: "textClip.mp4",
    link: "https://crayo.ai/create/text-video?step=1",
    title: "Fake text video",
    subtitle: "Create text conversation shorts",
  },
  {
    clip: "clip2.mp4",
    link: "https://crayo.ai/create/story-video?step=1",
    title: "Reddit convo video",
    subtitle: "Create fictional Reddit-story shorts",
  },
  {
    clip: "clip1.mp4",
    link: "https://crayo.ai/create/split-video?step=1",
    title: "Split-screen video",
    subtitle: "Create split-screen shorts with gameplay",
  },
];

type Video = (typeof videoData)[number];

export const VideoDemos = ({ className }: { className?: string }) => (
  <div
    className={cn(
      "flex flex-col justify-center space-y-4 md:flex-row md:space-y-0 lg:space-y-0",
      className,
    )}
  >
    {videoData.map((video, index) => (
      <VideoCard video={video} key={index} />
    ))}
  </div>
);

const VideoCard = ({ video }: { video: Video }) => (
  <Link
    href={appendUtmParams(video.link)}
    className="duration-400 group mx-2 w-[260px] flex-shrink-0 space-y-2 rounded-3xl px-4 py-2 text-start transition-all hover:bg-crayoBlue/10"
  >
    <VideoPlayer clip={video.clip} />
    <div className="flex flex-col items-center space-y-2">
      <div className="flex w-full flex-row items-center space-x-2">
        <h4 className="font-regular mt-2 text-xl">{video.title}</h4>
        <Image
          src="https://cdn-crayo.com/lp/public/rightArrow.png"
          alt="arrow"
          width={100}
          height={100}
          className="h-5 w-3 transform pt-2 transition-all duration-300 ease-in-out group-hover:translate-x-2"
        />
      </div>
      <p className="pb-2 text-base font-medium leading-[120%] text-zinc-400">{video.subtitle}</p>
    </div>
  </Link>
);
